<template>
  <div>
    <a-modal
      title="Change OTP Receive Method"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="onSubmit"
      html-type="submit"
      @cancel="$emit('close', true)"
      footer=""
      width="400px"
    >
      <a-form
        layout="vertical"
        :model="form"
        :rules="rules"
        name="OTPSelection"
        class="user-form"
        @finish="onSubmit"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="Email"
            id="Email"
            v-model="form.otp_method"
            required
            checked
          />
          <label class="form-check-label" for="Email">
            Email
          </label>
          <br />
          <input
            class="form-check-input"
            type="radio"
            value="Phone"
            id="Phone Number"
            v-model="form.otp_method"
          />
          <label class="form-check-label" for="Phone Number">
            Phone Number
          </label>
          <br />
        </div>
        <div class="buttons-w mt-2">
          <a-button
            type="primary"
            size="medium"
            html-type="submit"
            class="btn-block"
          >
            <span>Set OTP Method</span>
          </a-button>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import logo from "../../assets/img/assembly-cp-logo.png";
import { mapActions } from "vuex";
import { ref } from "vue";

export default {
  data() {
    return {
      disableResendButton: false,
      form: {
        otp_method: "Email",
      },
    };
  },
  emits: ["close"],
  setup() {
    const confirmLoading = ref(false);
    const visible = ref(true);
    const rules = {
      otp_method: [
        {
          required: true,
          trigger: "submit",
          message: "One option is required",
        },
      ],
    };
    const showModal = () => {
      visible.value = true;
    };
    return { logo, rules, visible, confirmLoading, showModal };
  },

  methods: {
    ...mapActions(["setOTPMethod"]),
    onSubmit() {
       if (
        this.setOTPMethod({
          receive_otp_on: this.form.otp_method,
        })
      ) {
        this.visible = false;
      }
    },
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}

.user-form {
  width: 60%;
  margin-top: 20px;
}

.center {
  display: flex;
  align-items: center;
}

.header-icon {
  font-size: 60px;
  color: #047bf8;
}

.pre-icon {
  font-size: 24px;
  color: #047bf8;
  margin-left: -12px;
}

.form-link {
  text-align: center;
  margin: 24px 0;
}

.form-link a {
  text-decoration: none;
  font-size: 16px;
}

.form-link a:hover {
  text-decoration: underline;
}
</style>
